import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

type Props = {
  setSort: (sort: string) => void;
  setType: (type: string) => void;
  sort: string;
  type: string;
};

const SearchFilters = (props: Props) => {
  const { setSort, setType, sort, type } = props;
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#DFF0D8",
        padding: "20px 20px 10px 20px",
      }}
    >
      <Form.Row className="filters-form">
        <Form.Group as={Col} controlId="formSortBy">
          <Form.Label>Sort By</Form.Label>
          <Form.Control
            as="select"
            defaultValue={sort}
            onChange={(e) => setSort(e.target.value)}
          >
            <option>Relevance</option>
            <option>Alphabetical (A-Z)</option>
            <option>Alphabetical (Z-A)</option>
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} controlId="formType">
          <Form.Label>Type</Form.Label>
          <Form.Control
            as="select"
            defaultValue={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option>Any</option>
            <option>School</option>
            <option>Club</option>
          </Form.Control>
        </Form.Group>
      </Form.Row>
    </div>
  );
};

export default SearchFilters;
