import * as actionTypes from "../actionTypes";

const initialState = {
  searched: false,
};

const searchedReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SEARCHED:
      return action.payload;
    default:
      return state;
  }
};

export default searchedReducer;
