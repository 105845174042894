import React, { useState, useEffect } from "react";
import SearchResults from "../components/SearchResults";
import { ClipLoader } from "react-spinners";
import searchObject from "./SearchBarContainer";

export interface searchResultObject {
  name: string;
  shortName: string;
  shortDescription: string;
  type: string;
  city: string;
  state_province: string;
}

type Props = {
  searchResults: Array<searchResultObject>;
  searchBody: typeof searchObject;
};

const SearchResultsContainer = (props: Props) => {
  const { searchResults, searchBody } = props;
  const [imagesLoaded, setImagesLoaded] = useState<boolean>(false);

  useEffect(() => {
    setImagesLoaded(false);
  }, [searchResults]);

  const onLastCardImageLoad = () => {
    setImagesLoaded(true);
  };

  return (
    <>
      {(!searchResults || (!imagesLoaded && searchResults.length > 0)) && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <ClipLoader color={"#39aa53"} size={50} />
        </div>
      )}
      <div
        {...((!searchResults ||
          (!imagesLoaded && searchResults.length > 0)) && {
          style: { display: "none" },
        })}
      >
        <SearchResults
          searchResults={searchResults}
          query={searchBody.query ? searchBody.query.toString() : ""}
          onLastCardImageLoad={onLastCardImageLoad}
        />
      </div>
    </>
  );
};

export default SearchResultsContainer;
