import React, { useState, FormEvent } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import { withRouter, RouteComponentProps } from "react-router-dom";
import qs from "qs";
import SearchFilters from "./SearchFilters";
import AddToDirectoryDialog from "./AddToDirectoryDialog";

type Props = {
  showAddToDirectory?: boolean;
  showFilters?: boolean;
  setQuery: (q: string) => void;
  setSort: (sort: string) => void;
  setType: (type: string) => void;
  onSubmit: (event: FormEvent) => void;
  sort: string;
  type: string;
} & RouteComponentProps;

const SearchBar = (props: Props) => {
  const {
    showAddToDirectory,
    showFilters,
    setQuery,
    setSort,
    setType,
    onSubmit,
    location,
    sort,
    type,
  } = props;
  const [searchInputValue, setSearchInputValue] = useState<string>(
    qs.parse(location.search, { ignoreQueryPrefix: true }).query
      ? qs.parse(location.search, { ignoreQueryPrefix: true }).query.toString()
      : ""
  );
  const [filtersCollapsed, setFiltersCollapsed] = useState<boolean>(true);
  const [showDirectoryDialog, setShowDirectoryDialog] = useState<boolean>(
    false
  );
  let searchInput = null;

  const handleChangeSearch = (event) => {
    setSearchInputValue(event.target.value);
    setQuery(event.target.value);
  };

  const handleToggleFiltersCollapsed = () => {
    setFiltersCollapsed(!filtersCollapsed);
  };

  const handleClearSearch = () => {
    setSearchInputValue("");
    searchInput.focus();
  };

  return (
    <>
      <AddToDirectoryDialog
        show={showDirectoryDialog}
        handleClose={() => {
          setShowDirectoryDialog(false);
        }}
      />
      <Form onSubmit={(event) => onSubmit(event)}>
        <Form.Row className="align-items-center">
          <Col xs="10" className="my-1">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text style={{ background: "none" }}>
                  <img
                    src={require("../assets/icons/search.png")}
                    width="24"
                    height="24"
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                size="lg"
                placeholder="Search for Schools, Clubs, and Volunteer Opportunities in Your Area"
                onChange={handleChangeSearch}
                value={searchInputValue}
                className={
                  searchInputValue.trim().length > 0
                    ? "search-bar"
                    : "search-bar-empty"
                }
                ref={(input) => {
                  searchInput = input;
                }}
              />
              {searchInputValue.trim().length > 0 && (
                <InputGroup.Append>
                  <Button
                    variant="outline-secondary"
                    className="clear-text-button"
                    onClick={handleClearSearch}
                  >
                    x
                  </Button>
                </InputGroup.Append>
              )}
            </InputGroup>
          </Col>
          <Col xs="2" className="my-1">
            <Button
              type="submit"
              variant="success"
              size="lg"
              block
              style={{ fontWeight: 600 }}
            >
              Search
            </Button>
          </Col>
        </Form.Row>
        <Accordion>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="my-1">
              {showAddToDirectory && (
                <Form.Text
                  style={{ fontSize: 16, color: "#34AA25" }}
                  className="green-link"
                  onClick={() => {
                    setShowDirectoryDialog(true);
                  }}
                >
                  Add My Organization to the Directory
                </Form.Text>
              )}
            </div>
            <div className="my-1">
              {showFilters && (
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="0"
                  style={{ fontSize: 16, color: "#34AA25" }}
                  className="no-select-box"
                  onClick={handleToggleFiltersCollapsed}
                >
                  {filtersCollapsed ? "Show filters" : "Hide filters"}
                </Accordion.Toggle>
              )}
            </div>
          </div>
          <Accordion.Collapse eventKey="0">
            <SearchFilters
              setSort={setSort}
              setType={setType}
              sort={sort}
              type={type}
            />
          </Accordion.Collapse>
        </Accordion>
      </Form>
    </>
  );
};

export default withRouter(SearchBar);
