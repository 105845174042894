import React from "react";
import { searchResultObject } from "../containers/SearchResultsContainer";
import SearchResultCard from "../components/SearchResultCard";

type Props = {
  searchResults: Array<searchResultObject>;
  query?: string;
  onLastCardImageLoad: () => void;
};

const SearchResults = (props: Props) => {
  const { searchResults, query, onLastCardImageLoad } = props;
  let resultsText = "";

  const renderCards = () => {
    if (searchResults && searchResults.length > 0) {
      return searchResults.map((card, index) => {
        return (
          <div>
            <SearchResultCard
              name={card.name}
              shortName={card.shortName}
              shortDescription={card.shortDescription}
              type={card.type}
              city={card.city}
              state_province={card.state_province}
              {...(index === searchResults.length - 1 && {
                onCardImageLoad: onLastCardImageLoad,
              })}
            />
            <br />
          </div>
        );
      });
    }
    return <></>;
  };

  if (searchResults && searchResults.length > 0) {
    resultsText = searchResults.length + " result";
    if (searchResults.length > 1) {
      resultsText += "s";
    }
    if (query.trim().length > 0) {
      resultsText += ' for "' + query + '"';
    }
  } else {
    resultsText = "No results were found.";
  }

  return (
    <>
      <p>{resultsText}</p>
      {renderCards()}
    </>
  );
};

export default SearchResults;
