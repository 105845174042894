import React from "react";
import CardDeck from "react-bootstrap/CardDeck";
import FeaturedCard from "./FeaturedCard";

interface featuredObject {
  name: string;
  shortName: string;
  shortDescription: string;
  type: string;
}

type Props = {
  featured: Array<featuredObject>;
};

const Featured = (props: Props) => {
  const { featured } = props;

  const renderCards = () => {
    const groupSize: number = 3;
    let cardData: Array<featuredObject | undefined> = props.featured;
    const initialLength: number = cardData.length;

    // appearance of 3 columns for each CardDeck
    for (let i = 0; i < groupSize - (initialLength % 3); i++) {
      cardData.push(undefined);
    }

    // surround every 3 cards with a CardDeck component
    return featured
      .map((card) => {
        if (!card) {
          return <FeaturedCard invisible={true} />;
        }
        return (
          <FeaturedCard
            name={card.name}
            shortName={card.shortName}
            shortDescription={card.shortDescription}
            type={card.type}
          />
        );
      })
      .reduce((reducer: Array<any>, element, index) => {
        index % groupSize === 0 && reducer.push([]);
        reducer[reducer.length - 1].push(element);
        return reducer;
      }, [])
      .map((deckContent) => {
        return <CardDeck>{deckContent}</CardDeck>;
      });
  };

  return <>{renderCards()}</>;
};

export default Featured;
