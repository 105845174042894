import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

const NavBar = () => {
  return (
    <Navbar
      bg="none"
      variant="light"
      expand="lg"
      style={{ paddingLeft: 0, paddingRight: 0 }}
    >
      <Navbar.Brand href="/">
        <img
          src={require("../assets/img/owl_header_logo.png")}
          width="180"
          height="auto"
          className="d-inline-block align-top"
          alt="React Bootstrap logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse>
        <Nav className="ml-auto main-nav">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="https://www.owlwise.com/about/">About</Nav.Link>
          <Nav.Link href="//owlwise.com/login">Sign In</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
