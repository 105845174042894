import React from "react";
import Navbar from "../NavBar";
import HeaderContainer from "../../containers/HeaderContainer";
import FeaturedContainer from "../../containers/FeaturedContainer";

function App(props) {
  return (
    <div className="App">
      <Navbar />
      <HeaderContainer showTitle={true} />
      <FeaturedContainer />
    </div>
  );
}

export default App;
